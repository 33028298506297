import React, { Component } from "react";
import logo from './logos/white_logo.svg';
import './Landing.css';

class Landing extends Component {
  render() {
    return (
      <div className="landing">
        <header className="landing-header">
          <img src={logo} className="landing-logo" alt="cardbound-logo" />
          <br></br>
          <a
            className="landing-link"
            href="https://www.ebay.com/str/pokedarkness"
            rel="noopener noreferrer"
          >
            Shop on eBay
          </a>
        </header>
      </div>
    );
  }
}

export default Landing;
